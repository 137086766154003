<template>
    <div class="component">
        <div class="input-group-append custom-file-append">
            <label class="input-group-text bg-danger" :for="id">
                <i class="fas fa-trash text-white" v-if="!checked"></i>
                <i class="fas fa-trash-restore text-white" v-else></i>
            </label>
            <input v-model="checked" type="checkbox" :id="id" :name="name" class="d-none">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                checked: false,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .custom-file-append {
        .input-group-text {
            height: calc(1.6em + 0.75rem + 2px);
        }
    }
</style>