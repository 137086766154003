<template>
    <div :class="{ 'custom-file': true, 'mb-3': hasError }">
        <div class="component">
            <input :class="{ 'custom-file-input': true, 'is-invalid': hasError }" @change="updateFileName"
                   :name="column" type="file" :id="column" :multiple="multiple" :accept="accept !== '' ? accept : '*'">
            <label :for="column" class="custom-file-label" ref="label">{{ files }}</label>
            <slot class="my-5"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            column: {
                type: String,
                required: true
            },
            hasError: {
                type: Boolean,
                required: true
            },
            label: {
                type: String,
                required: true
            },
			multiple: {
				type: Boolean,
				required: true
			},
			accept: {
				type: String,
				default: ''
			},
        },
        data() {
            return {
                'files': this.label
            }
        },
        methods: {
            updateFileName(event) {
                var files = [];

                for(var i = 0; i < event.target.files.length; i++) {
                    files.push(event.target.files[i].name)
                }

                this.files = files.join(', ');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .custom-file-append {
        .input-group-text {
            height: 2.8125rem;
        }
    }
</style>