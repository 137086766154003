/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require('jquery');

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/**
 * Wait for DOM ready.
 */
$(document).ready(function () {
    /**
	 * Firstly, we will create a fresh Vue application instance and attach it to
     * the page. Then, you may begin adding components to this application
     * or customize the JavaScript scaffolding to fit your unique needs.
     */
    const app = new Vue({
        el: '#app'
    });

    /**
	 * Next, we will load all of this project's JavaScript dependencies.
	 * It is a great starting point when building robust, powerful
	 * web applications.
     */
    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
    });

	/**
	 * Lazy Load delays loading of images in long web pages. Images outside of viewport
	 * will not be loaded before user scrolls to them. This is opposite of
	 * image preloading.
	 */
	$("img.lazy").lazyload();

    /**
     * Make book page iframe automatically adjust
     * width and height according to their contents
     */
    $('.iframe-book-page').on('load', function () {
        this.style.width = this.contentWindow.document.body.scrollWidth + 'px';
        this.style.height = this.contentWindow.document.body.scrollHeight + 'px';
    });
});
